<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="text-right" cols="12" md="6">
          <b-row>
            <b-col cols="12">
              <img src="../assets/iruav-logo.png" class="img-fluid" width="260">
            </b-col>
            <b-col cols="12">
              <img src="../assets/background.jpg" class="img-fluid" style="width: 250px;">
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6">
          <div class="border rounded p-3" style="width: 250px;">
            <b-form-group
              label="帳號"
              description="電子郵件"
              class="mb-2"
            >
              <b-form-input
                v-model="form.email"
                type="email"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>
  
            <b-form-group
              label="密碼"
              class="mb-4"
            >
              <b-form-input
                v-model="form.password"
                type="password"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>

            <div class="text-center">
              <b-button
                class="mr-4"
                type="button"
                variant="outline-success"
                @click="$bvModal.show('fm-register')"
              >註冊
              </b-button>

              <b-button
                type="button"
                variant="outline-primary"
                @click="login()"
              >登入
              </b-button>
              
              <a href="https://www.iruav.com.tw/" class="d-block link-secondary mt-2">返回官網</a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="fm-register" hide-footer>
      <template #modal-title>
        註冊
      </template>
      <div>
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://iruav.com.tw/iruavapp-register/"
          allowfullscreen
          height="710"
        ></b-embed>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    login () {
      this.axios
        .post('/login', {
          email: this.form.email,
          password: this.form.password
        })
        .then(response => {
          if (response.data.status) {
            localStorage.setItem('token', response.data.token);
            this.$store.commit('setToken', response.data.token);

            this.axios
              .get('/profile')
              .then(response => {
                if (response.data.status) {
                  localStorage.setItem('user', JSON.stringify(response.data.data));
                  this.$store.commit('setUser', response.data.data);
                  
                  // Check if the user is an admin
                  const isAdmin = response.data.data.admin === 1;

                  // Redirect based on admin status
                  if (isAdmin) {
                    this.$router.push({ path: '/admin/company' });
                  } else {
                    this.$router.push({ path: '/taiwan-map' });
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.makeToast('系統訊息', 'danger', '登入失敗！');
          }
        })
        .catch(error => {
          console.log(error);
          this.makeToast('系統訊息', 'danger', '登入失敗！');
        });
    }
  }
}
</script>

<style scoped>
.embed-responsive {
  height: 701px;
}
</style>