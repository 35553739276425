<template>
  <div id="app" class="d-flex flex-column" :class="{ 'vh-100': $route.path === '/login' }">
    <div class="d-flex flex-column justify-content-center flex-grow-1">
      <b-container v-if="$route.path != '/report'" fluid class="mb-2 mt-3">
        <b-row v-if="$route.path != '/login'" class="py-2 px-2">
          <b-col>
            <header class="mt-2 mb-3">
              <h1>艾瑞遙航科技有限公司</h1>
              <h2>{{ $store.state.user.company_name }}</h2>
            </header>
          </b-col>
          <b-col class="text-right">
            <span style="vertical-align: middle;">{{ $store.state.user.name }}</span>
            <b-button
              variant="link"
              @click="logout()"
            >登出</b-button>
          </b-col>
        </b-row>
      </b-container>

      <b-navbar v-if="$route.path != '/login' && $route.path != '/report'" type="dark" variant="dark" class="px-3 mb-3">
        <b-navbar-nav>
          <b-nav-item to="/admin/company" :active="$route.path == '/admin/company'">公司</b-nav-item>
          <b-nav-item to="/admin/user" :active="$route.path == '/admin/user'">使用者</b-nav-item>
          <b-nav-item to="/admin/case-scene" :active="$route.path == '/admin/case-scene'">案場</b-nav-item>
          <b-nav-item to="/admin/detection" :active="$route.path == '/admin/detection'">檢測</b-nav-item>
          <b-nav-item to="/admin/thermal-defect" :active="$route.path == '/admin/thermal-defect'">熱缺陷</b-nav-item>
          <b-nav-item to="/admin/identity" :active="$route.path == '/admin/identity'">身份</b-nav-item>
          <b-nav-item to="/admin/capacity" :active="$route.path == '/admin/capacity'">裝置容量</b-nav-item>
          <b-nav-item to="/admin/weather" :active="$route.path == '/admin/weather'">天氣狀況</b-nav-item>
          <b-nav-item to="/admin/clean" :active="$route.path == '/admin/clean'">清潔狀況</b-nav-item>
          <b-nav-item to="/admin/thermal-camera-pixel" :active="$route.path == '/admin/thermal-camera-pixel'">熱像儀像素</b-nav-item>
          <b-nav-item to="/admin/thermal-defect-category" :active="$route.path == '/admin/thermal-defect-category'">熱缺陷種類</b-nav-item>
          
          <!-- Taiwan Map dropdown -->
          <label class="ml-4 mr-2 mt-auto mb-auto" style="color: rgba(255, 255, 255, 0.5);">台灣地圖</label>
          <select v-model="adminCompanyId" class="form-select" @change="checkCompany" style="text-align: center; max-width: 108px;">
            <option selected disabled hidden value="0">-請選擇-</option>
            <template
                v-for="item in resources.companies"
              >
              <option
                  :key="item.id"
                  :value="item.id"
                  style="text-align: left;"
                >
                {{ item.id }} {{ item.name }}
              </option>
            </template>
          </select>
        </b-navbar-nav>
      </b-navbar>

      <b-container fluid class="mb-3">
        <b-row>
          <b-col>
            
            <router-view/>

          </b-col>
        </b-row>
        <b-row v-if="$route.path === '/login'">
          <b-col cols="12">
            <p class="text-center mt-3">
              專利證書號：M659668 / 技術報告代碼 6
            </p>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              @click="$bvModal.show('fm-modal')"
              variant="link"
            >隱私權政策</b-button>
          </b-col>
        </b-row>  
      </b-container>
    </div>

    <b-modal id="fm-modal" size="xl" hide-footer>
      <template #modal-title>
        隱私權政策
      </template>
      <div>
        <p>為了讓您能夠安心使用本平台的各項服務，特此向您說明本平台的隱私權政策，以保障您的權益，請詳閱下列內容：</p>

        <h3>一、隱私權保護政策的適用範圍</h3>
        <p>隱私權保護政策內容，包括本平台如何處理您在平台內的資料，隱私權保護政策不適用於本平台以外的相關連結網站，也不適用於非本平台所委託或參與管理的人員。</p>
        
        <h3>二、客戶資料的蒐集、處理及利用方式</h3>
        <p>當客戶使用本平台所提供之功能服務時，我們將視作業需要，請客戶提供必要的資料，並在該特定目的範圍內處理及利用客戶提供資料，非經客戶書面同意，本平台不會將客戶的資料提供平台內其他客戶或平台之外的用途。</p>
        
        <h3>三、資料之保護</h3>
        <p>本平台主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護平台運作及客戶的資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。如因業務需要有必要委託其他單位提供服務時，本平台亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其確實遵守。</p>
        
        <h3>四、資料之查閱與刪除</h3>
        <p>客戶能隨時登入平台查閱資料並下載自身專案內資料。專案資料在平台儲存空間足夠下將永久為客戶保存，若儲存空間不足或資料過期時我們將在知會客戶後進行刪除。</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adminCompanyId: '0',
      resources: {
        companies: [],
      }
    };
  },
  methods: {
    logout () {
      const config = {
        headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
      };

      this.axios
        .get('/logout', config)
        .then((response) => {
          if (response.status) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            this.$store.commit('setToken', null);
            this.$store.commit('setUser', {});
            
            this.$router.push({ path: '/login' });
          }
        })
        .catch(error => {
          console.log(error);

          // localStorage.removeItem('token');
          // localStorage.removeItem('user');
          
          // this.$router.push({ path: '/login' });

          // this.makeToast('系統訊息', 'danger', '此帳號其他人已登出！');
        });
    },

    /**
     * Get all the companies
     */
    getCompanies() {
      this.axios.get('/company')
        .then(response => {
          this.resources.companies = response.data.data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },

    checkCompany() {
      let userData = localStorage.getItem('user');
      if (userData) {
        let user = JSON.parse(userData);
        user.company_id = parseInt(this.adminCompanyId); 
        let updatedUserData = JSON.stringify(user);
        localStorage.setItem('user', updatedUserData);
      }
      this.$router.push('/taiwan-map');
    }
  },
  mounted() {
    if (!this.resources.companies.length && this.$route.path.includes("admin/")) {
      this.getCompanies();
    }
  },
  updated() {
    if (!this.resources.companies.length) {
      this.getCompanies();
    }
  }
}
</script>

<style scoped>
header img {
  height: 65px;
  width: auto;
}
header h1 {
  font-size: 1.65rem;
}
header h2 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
